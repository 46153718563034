<template>
    <b-card class="mt-1">
        <b-card-text>
            <b-row>
                <b-col cols="11">
                    <b-row>
                        <b-col cols="6">
                            <zw-select-group v-model="inputVal.attribute"
                                             :options="attributes"
                                             name="attribute"
                                             validate="required"
                                             disable-label
                            ></zw-select-group>
                        </b-col>
                        <b-col cols="6">
                            <zw-select-group v-model="inputVal.condition"
                                             :options="conditions"
                                             name="condition"
                                             validate="required"
                                             disable-label
                            ></zw-select-group>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col cols="11">
                    <component
                        v-if="Object.keys(fields).includes(attributeModel) && Object.keys(fields[attributeModel]).includes(inputVal.attribute)"
                        v-bind:is="fields[attributeModel][inputVal.attribute]"
                        v-model="inputVal"
                    ></component>
                    <template v-else-if="['date_before','date_after'].includes(inputVal.condition)">
                        <input type="hidden" v-model="inputVal.value">
                        <b-row>
                            <b-col>
                                <zw-input-group v-model="customInput"
                                                name="value"
                                                disable-label
                                                type="number"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>
                            <b-col>
                                <zw-select-group v-model="timeType"
                                                 :options="timeTypeOptions"
                                                 name="time_type"
                                                 validate="required"
                                                 disable-label
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-else-if="inputVal.condition=='date_comparison'">
                        <input type="hidden" v-model="inputVal.value">
                        <b-row>
                            <b-col>
                                <zw-select-group v-model="dateCondition"
                                                 :options="{'==':'=','>>':'>','<<':'<','>=':'>=','<=':'<='}"
                                                 name="asd"
                                                 validate="required"
                                                 disable-label
                                ></zw-select-group>
                            </b-col>
                            <b-col>
                                <zw-input-group v-model="customInput"
                                                name="value"
                                                disable-label
                                                type="number"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>
                            <b-col>
                                <zw-select-group v-model="timeType"
                                                 :options="timeTypeOptions"
                                                 name="time_type"
                                                 validate="required"
                                                 disable-label
                                ></zw-select-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-alert variant="info" show>
                                    <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
                                    {{ getSelectInfoText(inputVal) }}
                                </b-alert>
                            </b-col>
                        </b-row>
                    </template>
                    <template v-else-if="inputVal.condition=='date'">
                        <zw-select-group v-model="inputVal.value"
                                         :options="dateValues"
                                         name="value"
                                         disable-label
                                         validate="required"
                        ></zw-select-group>
                    </template>
                    <template v-else-if="inputVal.attribute && inputVal.attribute.startsWith('_custom_')">
                        <multiselect v-model="inputVal.value"
                                     :searchable="true"
                                     :options="Object.keys(getCustomFieldOptions(inputVal.attribute))"
                                     :multiple="inputVal.condition=='in'"
                                     :custom-label="opt => getCustomFieldOptions(inputVal.attribute)[opt]"
                                     select-label=""
                                     v-if="Object.keys(getCustomFieldOptions(inputVal.attribute)).length"
                        ></multiselect>
                        <zw-input-group v-else
                                        v-model="inputVal.value"
                                        name="value"
                                        disable-label
                        ></zw-input-group>
                    </template>
                    <template v-else>
                        <zw-input-group v-model="inputVal.value"
                                        name="value"
                                        disable-label
                        ></zw-input-group>
                    </template>
                </b-col>
                <b-col cols="1">
                    <b-button v-if="remove" variant="danger" @click="remove(index)" :title="$t('common.title.delete')">
                        <font-awesome-icon icon="trash"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card-text>
    </b-card>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from "moment";
import Category from "@/components/workflow/attributes/offering/category.vue";

export default {
    name: 'Condition',
    components: {Category},
    props: {
        index: [Number, String],
        value: [Object],
        attributes: [Array, Object],
        conditions: [Array, Object],
        timeTypes: [Array, Object],
        dateValues: [Array, Object],
        remove: [Function],
        attributeModel: {
            type: String,
            default: () => {
                return 'offering'
            }
        }
    },
    data() {
        return {
            customInput: null,
            dateCondition: null,
            timeType: 'hours',
            fields: {
                'offering': {
                    'category': () => import('./workflow/attributes/offering/category'),
                    'document_sent': () => import('./workflow/attributes/offering/document_sent'),
                    'fulfilment_status': () => import('./workflow/attributes/offering/fulfilment_status'),
                    'invoice_status': () => import('./workflow/attributes/offering/invoice_status'),
                    'payment_status': () => import('./workflow/attributes/offering/payment_status'),
                    'payment_method': () => import('./workflow/attributes/offering/payment_method'),
                    'shipping_status': () => import('./workflow/attributes/offering/shipping_status'),
                    'source': () => import('./workflow/attributes/offering/source'),
                    'status': () => import('./workflow/attributes/offering/status'),
                },
                'customer': {
                    'customer_status': () => import('./workflow/attributes/customer/customer_status'),
                    'customer_tags': () => import('./workflow/attributes/customer/customer_tags'),
                    'customer_groups': () => import('./workflow/attributes/customer/customer_groups'),
                    'care_user': () => import('./workflow/attributes/customer/customer_care_user'),
                },
                'todo': {
                    'status': () => import('./workflow/attributes/todo/status'),
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch('CustomFields/fetchFieldsByModel', {model: this.attributeModel})
    },
    methods: {
        ...mapGetters('CustomFields', ['getModelFields']),
        ...mapGetters('CommonData', ['getDocuments']),
        updateTimeField() {
            const map = {
                minutes: 'm',
                hours: 'h',
                days: 'd'
            }
            if (['date_before', 'date_after'].includes(this.inputVal.condition)) {
                this.inputVal.value = this.customInput + map[this.timeType]
            } else {
                this.inputVal.value = this.dateCondition + this.customInput + map[this.timeType]
            }
        },
        getCustomFieldOptions(name) {
            const field = this.getModelFields().find(field => {
                return '_custom_' + field.name == name
            })
            if (field) {
                if (field.options.options) {
                    return field.options.options
                }
            }
            return []
        },
        getSelectInfoText(data) {
            let text = 'Will select entries with ' + data.attribute;
            let calculatedDate = moment().subtract(this.customInput, this.timeType)
            if (this.dateCondition == '==') {
                text += ' equals ' + calculatedDate.format('DD.MM.YYYY')
            } else if (this.dateCondition == '>>') {
                text += ' earlier than ' + calculatedDate.format('DD.MM.YYYY HH:mm')
            } else if (this.dateCondition == '<<') {
                text += ' after ' + calculatedDate.format('DD.MM.YYYY HH:mm')
            } else if (this.dateCondition == '>=') {
                text += ' earlier or equal than ' + calculatedDate.format('DD.MM.YYYY HH:mm')
            } else if (this.dateCondition == '<=') {
                text += ' after or equal ' + calculatedDate.format('DD.MM.YYYY HH:mm')
            }
            return text;
        },
    },
    computed: {
        inputVal: {
            get() {
                const map = {
                    'm': 'minutes',
                    'h': 'hours',
                    'd': 'days'
                }
                if (this.value && this.value.value && ['date_before', 'date_after'].includes(this.value.condition) && this.value.value.length) {
                    this.customInput = this.value.value.substring(0, this.value.value.length - 1);
                    this.timeType = map[this.value.value.substr(this.value.value.length - 1)];
                }

                if (this.value && this.value.value && ['date_comparison'].includes(this.value.condition) && this.value.value.length) {
                    this.dateCondition = this.value.value.substring(0, 2);
                    this.customInput = this.value.value.substring(2, this.value.value.length - 1);
                    this.timeType = map[this.value.value.substr(this.value.value.length - 1)];
                }

                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
        timeTypeOptions() {
            return this.timeTypes.map(val => {
                return {value: val, text: this.$t('settings.workflow.time_type.' + val)}
            })
        },
    },
    watch: {
        customInput: function (val) {
            this.updateTimeField()
        },
        dateCondition: function (val) {
            this.updateTimeField()
        },
        timeType: function (val) {
            this.updateTimeField()
        },
    }

}
</script>